import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import 'moment/locale/vi'
import locale from 'antd/es/locale/vi_VN'

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings: { l } }) => {
  return (
    <ConfigProvider locale={locale}>
      <IntlProvider locale='vi'>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
