import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'
import { routes, entity, common } from 'routes'
import { replaceAll } from 'utils'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout
}
const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  const { role } = user
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }
  const parsePathParams = (path = '') => {
    let _path = path
    Object.keys(entity).forEach(x => {
      _path = replaceAll(_path, entity[x], '')
    })
    Object.keys(common).forEach(x => {
      _path = replaceAll(_path, common[x], '')
    })
    // :id
    const id = replaceAll(_path, '/', '')
    return replaceAll(path, id, ':id')
  }
  const authorize = (routes, path, role) => {
    if (!path || path === '/') {
      return true
    }
    let route = routes.find(x => x.path === path)
    if (!route) {
      const originalPath = parsePathParams(path)
      route = routes.find(x => x.path === originalPath)
      if (!route) {
        // TODO: set false when comple dev stage
        return true
      }
    }

    const { roles } = route
    if (!roles || roles.includes('*') || roles.includes(role)) {
      return true
    }

    return false
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Redirect to='/auth/login' />
    }
    // redirect to 404 if current user does not have permission on this page
    if (!authorize(routes, pathname, role)) {
      return <Redirect to='/auth/404' />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <>
      <Helmet titleTemplate='DTMS | %s' title='HỆ THỐNG VẬN HÀNH VÀ GIÁM SÁT TRẠM BIẾN ÁP HẠ THẾ' />
      {BootstrappedLayout()}
    </>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
