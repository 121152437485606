import React, { useState } from 'react'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Button, Modal, Input, Form } from 'antd'
import styles from './style.module.scss'
const { Item } = Form

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }
  const onFinish = values => {
    delete values.confirm
    dispatch({
      type: 'account/CHANGE_PASSWORD',
      payload: {
        id: user.id,
        body: { ...values }
      }
    })
    setModal(false)
  }

  const [form] = Form.useForm()
  const [modal, setModal] = useState()
  const menu = (
    <Menu selectable={false}>
      <Menu.Item className='dropdown-title'>
        <span>Xin chào </span>
        <strong>
          {user.name || user.email || user.username}
        </strong>

      </Menu.Item>

      <Menu.Item>
        <div onClick={e => setModal(true)}>
          <i className="i_password" />
          Thay đổi mật khẩu
          {/* <FormattedMessage id="topBar.profileMenu.editProfile" /> */}
          {/* <Button onCLick={() }></Button> */}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          Đăng xuất 
          {/* <FormattedMessage id="topBar.profileMenu.logout" /> */}
        </div>
      </Menu.Item>
    </Menu>

  )
  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className='topbar-header'>
          <div className={styles.dropdown}>

            <div className="ico-user ant-dropdown-trigger">
              <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
            </div>
          </div>
        </div>
      </Dropdown>
      <Form
        className=''
        onFinish={onFinish}
        form={form}
      >
        <Modal
          className='modal-small pre-confirm-modal'
          title={<div><i className='i_password ico-white25' /><span>Xác nhận mật khẩu</span></div>}
          visible={modal}
          onOk={onFinish}
          onCancel={() => setModal(false)}
          width={400}
          footer={[
            <Button
              key='cancel'
              className='btn btn-create btn-cancel'
              htmlType='button'
              onClick={() => setModal(false)}
            >
              <i className='i_cancel ico25' />
              <strong>Hủy</strong>
            </Button>,
            <Button
              key='ok'
              className='btn btn-create btn-save'
              htmlType='submit'
              onClick={form.submit}
            >
              <i className='i_save_36 ico25' />
              <strong>Xác nhận</strong>
            </Button>

          ]}
        >
          <Item
            name='oldPassword'
            label={<span className='lbl-block'>Mật khẩu cũ</span>}
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu để xác nhận!' }]}
          >

            <Input.Password />
          </Item>
          <Item
            name='newPassword'
            label={<span className='lbl-block'>Nhập khẩu mới</span>}
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu để xác nhận!' }]}
          >

            <Input.Password />
          </Item>
          <Item
            name='confirm'
            label={<span className='lbl-block'>Nhập lại mật khẩu mới</span>}
            dependencies={['newPassword']}
            rules={[{
              required: true,
              message: 'Vui lòng nhập mật khẩu để xác nhận!'
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Mật khẩu không khớp!'))
              }
            })
            ]}
          >
            <Input.Password />
          </Item>
        </Modal>
      </Form>
    </>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
