export const ENTITY = 'cabinetGroup'
const actions = {
  // SET
  SET_STATE: `${ENTITY}/SET_STATE`,
  SET_LIST_STATE: `${ENTITY}/SET_LIST_STATE`,
  SET_CONFIGURE: `${ENTITY}/SET_CONFIGURE`,
  SET_CONFIGURE_STATE: `${ENTITY}/SET_CONFIGURE_STATE`,
  SET_CONTROL_STATUS: `${ENTITY}/SET_CONTROL_STATUS`,
  SET_CONTROL_STATUS_POLLING: `${ENTITY}/SET_CONTROL_STATUS_POLLING`,
  SET_SHORT_LIST: `${ENTITY}/SET_SHORT_LIST`,
  SET_SHORT_LIST_SEARCH: `${ENTITY}/SET_SHORT_LIST_SEARCH`,

  // GET
  COUNT: `${ENTITY}/COUNT`,
  LIST: `${ENTITY}/LIST`,
  SHORT_LIST: `${ENTITY}/SHORT_LIST`,
  SHORT_LIST_SEARCH: `${ENTITY}/SHORT_LIST_SEARCH`,
  DETAIL: `${ENTITY}/DETAIL`,
  CREATE: `${ENTITY}/CREATE`,
  UPDATE: `${ENTITY}/UPDATE`,
  DELETE: `${ENTITY}/DELETE`,
  GET_MOVINGS: `${ENTITY}/GET_MOVINGS`
}

export default actions
