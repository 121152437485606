import { all, put, call, takeEvery, delay } from 'redux-saga/effects'
import actions from './actions'
import { preConfirm } from 'services/jwt'
import { TYPES, callApi } from 'services'
import { getPublicSetting } from 'services/setting'
import { history } from 'index'
import { notification } from 'antd'

export const COMMON = ({ type, field, actionType }) => {
  return function * ({ payload }) {
    yield loading(true)
    if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1) {
      const { password } = payload.data
      yield loading(true)
      // preconfirm
      const success = yield call(preConfirm, password)
      if (!success) {
        yield put({
          type: 'account/SET_STATE',
          payload: {
            preConfirm: true
          }
        })
        notification.warning({
          message: 'Lỗi!',
          description: 'Mật khẩu không đúng!'
        })
        yield loading(false)
        return
      }
      delete payload.data.password
    }
    let response
    for (let i = 0; i < 5; i++) {
      try {
        response = yield call(callApi, { type, payload })
      } catch (error) {
        yield delay(2000)
      }
      if (response) {
        if (type.toLowerCase().indexOf('count') > -1) {
          response = response.count
        }
        yield put({
          type: actionType || 'setting/SET_STATE',
          payload: {
            [field]: response
          }
        })
        yield put({
          type: 'account/SET_STATE',
          payload: {
            preConfirm: false,
            success: true
          }
        })
        if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1) {
          notification.success({
            message: 'Thành công!',
            description: 'Cập nhật thành công!'
          })
          history.push('/settings')
        }
        yield loading(false)
        return
      }
      yield delay(2000)
    }

    yield loading(false)
    notification.warning({
      message: 'Không thể tải dữ liệu từ máy chủ',
      description: 'Không thể tải dữ liệu từ máy chủ, vui lòng kiểm tra đường truyền.'
    })
  }
}
export function * GET_PUBLIC_SETTING ({ payload }) {
  yield loading(true)
  const response = yield call(getPublicSetting, payload)
  if (response) {
    yield put({
      type: 'setting/SET_STATE',
      payload: {
        total: response.count
      }
    })
  }
  yield loading(false)
}
function * loading (isLoading = false) {
  yield put({
    type: 'setting/SET_STATE',
    payload: {
      loading: isLoading
    }
  })
}
export default function * rootSaga () {
  yield all([
    takeEvery(actions.DETAIL, GET_PUBLIC_SETTING),
    takeEvery(actions.UPDATE, COMMON({ type: TYPES.SETTINGS_PATCH_ID, field: 'detail' })),
    takeEvery(actions.ALERT_TYPE, COMMON({ type: TYPES.SETTINGS_GET_ALERT_TYPES, field: 'alerts' })),
    takeEvery(actions.DATA_TYPE, COMMON({ type: TYPES.SETTINGS_GET_DATA_TYPES, field: 'types' })),
    COMMON({ type: TYPES.SETTINGS_GET_ID, field: 'detail' })({ payload: { id: 'dashboard' } })
  ])
}
