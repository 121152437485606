export const ENTITY = 'monitoring'
const actions = {
  SET_STATE: `${ENTITY}/SET_STATE`,
  LIST: `${ENTITY}/LIST`,
  COUNT: `${ENTITY}/COUNT`,
  DETAIL: `${ENTITY}/DETAIL`,
  CREATE: `${ENTITY}/CREATE`,
  UPDATE: `${ENTITY}/UPDATE`,
  DELETE: `${ENTITY}/DELETE`,
  REALTIME: `${ENTITY}/REALTIME`,
  HISTORICAL_COUNT: `${ENTITY}/HISTORICAL_COUNT`,
  HISTORICAL_LIST: `${ENTITY}/HISTORICAL_LIST`,
  MONITORINGS_GET_CABINETGROUPS: `${ENTITY}/MONITORINGS_GET_CABINETGROUPS`,
  MONITORINGS_GET_WEBS: `${ENTITY}/MONITORINGS_GET_WEBS`,
  MONITORINGS_GET_ZONES: `${ENTITY}/MONITORINGS_GET_ZONES`,
  TS_HISTORICAL_LIST: `${ENTITY}/TS_HISTORICAL_LIST`
}
export default actions
