import { ROLE } from 'constant'
export default async function getMenuData ({ role, zoneId }) {
  const menus = [
    // {
    //   category: true,
    //   title: 'Menu'
    // },
    {
      title: 'TRANG CHỦ',
      key: 'dashboards',
      icon: 'i_home',
      url: '/home',
      roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR] // set user roles with access to this route
    },
    {
      title: 'TRẠM BIẾN ÁP',
      key: 'cabinet',
      icon: 'i_electricity_tower',
      url: '/cabinets',
      roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR] // set user roles with access to this route
    },
    {
      title: 'CẢNH BÁO',
      key: 'warning',
      icon: 'i_warning_49',
      url: '/warnings',
      roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR] // set user roles with access to this route
    },
    {
      title: 'QUẢN LÝ VẬN HÀNH',
      key: 'monitoring',
      icon: 'i_controls',
      url: role === ROLE.MANAGER ? `/monitorings/grid/${zoneId}` : '/monitorings',
      roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR] // set user roles with access to this route
    },
    {
      title: 'QUẢN LÝ TÀI KHOẢN',
      key: 'user',
      icon: 'i_user_8',
      url: '/accounts',
      roles: [ROLE.ADMIN, ROLE.MANAGER] // set user roles with access to this route
    },
    {
      title: 'QUẢN LÝ KHU VỰC',
      key: 'zones',
      icon: 'i_place',
      url: '/zones',
      roles: [ROLE.ADMIN] // set user roles with access to this route
    },
    {
      title: 'CÀI ĐẶT',
      key: 'setting',
      icon: 'i_setting',
      url: '/settings',
      roles: [ROLE.ADMIN] // set user roles with access to this route
    },
    {
      title: 'TRỢ GIÚP',
      key: 'help',
      icon: 'i_help',
      url: '/tro-giup',
      roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR] // set user roles with access to this route
    }
  ]
  return menus
}
