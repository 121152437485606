import apiClient from 'services/axios'
import { apis, types } from './api'

export const TYPES = types
export const callApi = async ({ type, payload }) => {
  const api = apis[type]
  if (!api || typeof api !== 'function') {
    return
  }
  const { method, url, body: data } = api(payload)
  const options = { method, url, data }
  const isBlob = url.toLowerCase().indexOf('export') > -1
  if (isBlob) {
    options.responseType = 'blob'
  }
  const response = await apiClient(options)
  return (response || {}).data
}

export const listApi = async (API = {}, { filter, limit = 100, skip = 0, name, zone, dtms, username, role }) => {
  let response = await apiClient.get(API.count)
  if (!response) {
    return
  }
  const { count: total } = response.data
  let url = `${API.list}?limit=${limit}&skip=${skip}`
  if (filter) {
    url += `&filter=${filter}`
  }
  if (name) {
    url += `&filter[where][name][like]=${name}`
  }
  if (zone) {
    url += `&filter[where][zone][like]=${zone}`
  }
  if (dtms) {
    url += `&filter[where][dtms][like]=${dtms}`
  }
  if (username) {
    url += `&filter[where][username][like]=${username}`
  }
  if (role) {
    url += `&filter[where][role][like]=${role}`
  }
  response = await apiClient.get(url)
  if (response) {
    return {
      list: response.data,
      total
    }
  }
}
export const detailApi = async (API = {}, id) => {
  const response = await apiClient.get(API.detail.replace('{0}', id))
  if (response) {
    return response.data
  }
}
export const createApi = async (API = {}, data) => {
  const response = await apiClient.post(API.create, data)
  if (response) {
    return response.data
  }
}
export const updateApi = async (API = {}, id, data) => {
  const response = await apiClient.put(API.update.replace('{0}', id), data)
  if (response) {
    return response.data
  }
}
export const destroyApi = async (API = {}, id) => {
  await apiClient.delete(API.delete.replace('{0}', id))
  return {}
}
