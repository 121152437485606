const ENTITY = 'account'
const actions = {
  // SET
  SET_STATE: `${ENTITY}/SET_STATE`,
  SET_LIST_STATE: `${ENTITY}/SET_LIST_STATE`,
  // GET
  COUNT: `${ENTITY}/COUNT`,
  LIST: `${ENTITY}/LIST`,
  DETAIL: `${ENTITY}/DETAIL`,
  CREATE: `${ENTITY}/CREATE`,
  UPDATE: `${ENTITY}/UPDATE`,
  DELETE: `${ENTITY}/DELETE`,
  BAN: `${ENTITY}/BAN`,
  OPERATOR_COUNT: `${ENTITY}/OPERATOR_COUNT`,
  // others
  PRE_CONFIRM: `${ENTITY}/PRE_CONFIRM`,
  FLUSH_PRE_CONFIRM: `${ENTITY}/FLUSH_PRE_CONFIRM`,
  CHANGE_PASSWORD: `${ENTITY}/CHANGE_PASSWORD`,
  SET_PASSWORD: `${ENTITY}/SET_PASSWORD`
}

export default actions
