import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import { count, list, detail, create, update, destroy, updateNote } from 'services/warning'
import { notification } from 'antd'

export function * COUNT ({ payload }) {
  const { where, start, end } = payload
  yield loading(true)
  const response = yield call(count, { where, start, end })
  if (response) {
    yield put({
      type: 'alert/SET_STATE',
      payload: {
        total: response.count
      }
    })
  }
  yield loading(false)
}
export function * LIST ({ payload }) {
  const { skip, limit, filter, start, end } = payload
  yield loading(true)
  const response = yield call(list, { skip, limit, filter, start, end })
  if (response) {
    const list = response // const list = [...]

    yield put({
      type: 'alert/SET_STATE',
      payload: {
        list
      }
    })
  }
  yield loading(false)
}
export function * DETAIL ({ payload }) {
  const { id } = payload
  yield loading(true)
  const response = yield call(detail, id)
  if (response) {
    yield put({
      type: 'alert/SET_STATE',
      payload: {
        detail: response
      }
    })
  }
  yield loading(false)
}
export function * CREATE ({ payload }) {
  const { data } = payload
  yield loading(true)
  const response = yield call(create, data)
  if (response) {
    yield put({
      type: 'alert/SET_STATE',
      payload: {
        detail: response
      }
    })
  }
  yield loading(false)
}
export function * UPDATE_NOTE ({ payload }) {
  yield loading(true)
  const response = yield call(updateNote, { ...payload })
  if (response) {
    yield put({
      type: 'alert/SET_NOTE_STATE',
      payload: response
    })
    notification.success({
      message: 'Thành công!',
      description: 'Cập nhật ghi chú thành công!'
    })
  }
  yield loading(false)
}
export function * UPDATE ({ payload }) {
  const { id, data } = payload
  yield loading(true)
  const response = yield call(update, id, data)
  if (response) {
    yield put({
      type: 'alert/SET_STATE',
      payload: {
        detail: response
      }
    })
  }
  yield loading(false)
}
export function * DELETE ({ payload }) {
  const { id } = payload
  yield loading(true)
  const response = yield call(destroy, id)
  if (response) {
    yield put({
      type: 'alert/SET_STATE',
      payload: {
        detail: response
      }
    })
  }
  yield loading(false)
}
function * loading (isLoading = false) {
  yield put({
    type: 'alert/SET_STATE',
    payload: {
      loading: isLoading
    }
  })
}
export default function * rootSaga () {
  yield all([
    takeEvery(actions.COUNT, COUNT),
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE_NOTE, UPDATE_NOTE)
  ])
}
