import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions, { ENTITY } from './actions'
import { preConfirm } from 'services/jwt'
import { TYPES, callApi } from 'services'
import { history } from 'index'
import { notification } from 'antd'
// import moment from 'moment'

export const COMMON = ({ type, field, actionType }) => {
  return function * ({ payload }) {
    yield loading(true)
    if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1 || type.toLowerCase().indexOf('delete') > -1) {
      const data = payload.data || payload.body
      const { password } = data
      yield loading(true)
      // preconfirm
      const success = yield call(preConfirm, password)
      if (!success) {
        yield put({
          type: 'account/SET_STATE',
          payload: {
            preConfirm: true
          }
        })
        notification.warning({
          message: 'Lỗi!',
          description: 'Mật khẩu không đúng!'
        })
        yield loading(false)
        return
      }
      delete data.password
    }
    let response = yield call(callApi, { type, payload })
    if (response) {
      if (type.toLowerCase().indexOf('count') > -1) {
        response = response.count
      }
      if (type.toLowerCase().indexOf('delete') > -1) {
        actionType = `${ENTITY}/SET_LIST_STATE`
        response = payload.id
      }
      yield put({
        type: actionType || `${ENTITY}/SET_STATE`,
        payload: {
          [field]: response
        }
      })
      if (type.toLowerCase().indexOf('post') > -1 &&
        type.toLowerCase().indexOf('accept') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `${payload.body.accept ? 'Chấp nhận' : 'Từ chối'} lắp Nhóm trạm biến áp ${response.name} thành công!`
        })
        yield loading(false)
        return
      }
      if (type.toLowerCase().indexOf('post') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `Nhóm Trạm biến áp ${response.name} đã được tạo thành công!`
        })
        history.push('/cabinet-groups')
      }
      if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `Nhóm Trạm biến áp ${response.name} đã được cập nhật thành công!`
        })
        history.push('/cabinet-groups')
      }
      if (type.toLowerCase().indexOf('delete') > -1) {
        notification.success({
          message: 'Thành công!',
          description: 'Nhóm Trạm biến áp đã được xóa thành công!'
        })
        yield put({
          type: 'cabinetGroup/LIST',
          payload: {
            id: payload.id,
            filter: JSON.stringify({
              order: ['name ASC', 'id ASC']
            })
          }
        })
      }
    }
    yield loading(false)
  }
}

export function * SHORT_LIST ({ payload }) {
  yield loading(true)

  const response = yield call(callApi, { type: TYPES.CABINETS_GET, payload })
  if (response) {
    yield put({
      type: `${ENTITY}/SET_SHORT_LIST`,
      payload: response
    })
  }
  yield loading(false)
}
export function * SHORT_LIST_SEARCH ({ payload }) {
  yield loading(true)

  const response = yield call(callApi, { type: TYPES.CABINETS_GET, payload })
  if (response) {
    yield put({
      type: `${ENTITY}/SET_SHORT_LIST_SEARCH`,
      payload: response
    })
  }
  yield loading(false)
}

function * loading (isLoading = false, timeout = 0) {
  yield put({
    type: `${ENTITY}/SET_STATE`,
    payload: {
      loading: isLoading
    }
  })
}
export default function * rootSaga () {
  yield all([
    takeEvery(actions.LIST, COMMON({ type: TYPES.ZONES_GET_ID_CABINETGROUPS, field: 'list' })),
    takeEvery(actions.CREATE, COMMON({ type: TYPES.ZONES_POST_ID_CABINETGROUPS, field: 'detail' })),
    takeEvery(actions.DETAIL, COMMON({ type: TYPES.ZONES_GET_ID_CABINETGROUPS_FK, field: 'detail' })),
    takeEvery(actions.UPDATE, COMMON({ type: TYPES.ZONES_PUT_ID_CABINETGROUPS_CABINETGROUPID, field: 'detail' })),
    takeEvery(actions.DELETE, COMMON({ type: TYPES.ZONES_DELETE_ID_CABINETGROUPS_CABINETGROUPID, field: 'id' })),
    takeEvery(actions.GET_MOVINGS, COMMON({ type: TYPES.ZONES_GET_ID_CABINETGROUPS_CABINETGROUPID_TOMOVING, field: 'movings' })),
    takeEvery(actions.SHORT_LIST, SHORT_LIST),
    takeEvery(actions.SHORT_LIST_SEARCH, SHORT_LIST_SEARCH)
  ])
}
