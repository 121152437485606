import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import simple from './simple/reducers'
import account from './account/reducers'
import cabinet from './cabinet/reducers'
import zone from './zone/reducers'
import alert from './warning/reducers'
import monitoring from './monitoring/reducers'
import setting from './setting/reducers'
import notification from './notification/reducers'
import cabinetGroup from './cabinet-group/reducers'
export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    simple,
    account,
    cabinet,
    zone,
    alert,
    monitoring,
    setting,
    notification,
    cabinetGroup
  })
