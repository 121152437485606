/* eslint camelcase: 0 */
/*
    Total: 95 APIs
    version: "1.16.0",
    title: "dtms-backend",
    description: "DTMS Backend API"
*/
import config from 'config/config'
const apiEndpoint = config.API_URL

export const types = {
  USERS_GET_ID_CABINETOPERATINGS: 'USERS_GET_ID_CABINETOPERATINGS',
  USERS_GET_ID_CABINETOPERATINGS_COUNT: 'USERS_GET_ID_CABINETOPERATINGS_COUNT',
  USERS_GET_ID: 'USERS_GET_ID',
  USERS_DELETE_ID: 'USERS_DELETE_ID',
  USERS_PATCH_ID: 'USERS_PATCH_ID',
  USERS_GET: 'USERS_GET',
  USERS_POST: 'USERS_POST',
  USERS_GET_COUNT: 'USERS_GET_COUNT',
  USERS_POST_ID_FCMS: 'USERS_POST_ID_FCMS',
  USERS_POST_RESET: 'USERS_POST_RESET',
  USERS_POST_LOGIN: 'USERS_POST_LOGIN',
  USERS_POST_LOGOUT: 'USERS_POST_LOGOUT',
  USERS_GET_CONFIRM: 'USERS_GET_CONFIRM',
  USERS_POST_RESEND_CONFIRM_EMAIL: 'USERS_POST_RESEND_CONFIRM_EMAIL',
  USERS_POST_ID_CHANGE_PASSWORD: 'USERS_POST_ID_CHANGE_PASSWORD',
  USERS_POST_ID_CHECK_PASSWORD: 'USERS_POST_ID_CHECK_PASSWORD',
  USERS_POST_RESET_PASSWORD: 'USERS_POST_RESET_PASSWORD',
  USERS_POST_ID_SUSPEND: 'USERS_POST_ID_SUSPEND',
  USERS_POST_ID_SET_PASSWORD: 'USERS_POST_ID_SET_PASSWORD',
  ZONES_GET_ID_CABINETGROUPS_FK: 'ZONES_GET_ID_CABINETGROUPS_FK',
  ZONES_GET_ID_CABINETGROUPS: 'ZONES_GET_ID_CABINETGROUPS',
  ZONES_POST_ID_CABINETGROUPS: 'ZONES_POST_ID_CABINETGROUPS',
  ZONES_POST: 'ZONES_POST',
  ZONES_GET: 'ZONES_GET',
  ZONES_GET_ID: 'ZONES_GET_ID',
  ZONES_DELETE_ID: 'ZONES_DELETE_ID',
  ZONES_PATCH_ID: 'ZONES_PATCH_ID',
  ZONES_GET_COUNT: 'ZONES_GET_COUNT',
  ZONES_POST_ID_CABINETS: 'ZONES_POST_ID_CABINETS',
  ZONES_GET_ID_CABINETGROUPS_CABINETGROUPID_TOMOVING: 'ZONES_GET_ID_CABINETGROUPS_CABINETGROUPID_TOMOVING',
  ZONES_PUT_ID_CABINETGROUPS_CABINETGROUPID: 'ZONES_PUT_ID_CABINETGROUPS_CABINETGROUPID',
  ZONES_DELETE_ID_CABINETGROUPS_CABINETGROUPID: 'ZONES_DELETE_ID_CABINETGROUPS_CABINETGROUPID',
  ZONES_GET_ID_STATS: 'ZONES_GET_ID_STATS',
  ZONES_POST_ID_USERS: 'ZONES_POST_ID_USERS',
  DEVICES_GET_ID_BROKER: 'DEVICES_GET_ID_BROKER',
  DEVICES_POST_ID_CABINET: 'DEVICES_POST_ID_CABINET',
  DEVICES_PUT_ID_CABINET: 'DEVICES_PUT_ID_CABINET',
  DEVICES_DELETE_ID_CABINET: 'DEVICES_DELETE_ID_CABINET',
  DEVICES_GET_ID: 'DEVICES_GET_ID',
  DEVICES_PATCH_ID: 'DEVICES_PATCH_ID',
  DEVICES_GET: 'DEVICES_GET',
  DEVICES_GET_COUNT: 'DEVICES_GET_COUNT',
  DEVICES_POST_TRIGGER_FOTA: 'DEVICES_POST_TRIGGER_FOTA',
  DEVICES_PUT_DEVICEID_DEVICEFIRMWAREVERSION: 'DEVICES_PUT_DEVICEID_DEVICEFIRMWAREVERSION',
  CABINETS_GET_ID_DEVICE: 'CABINETS_GET_ID_DEVICE',
  CABINETS_GET_ID_ATTACHBYUSER: 'CABINETS_GET_ID_ATTACHBYUSER',
  CABINETS_GET_ID: 'CABINETS_GET_ID',
  CABINETS_DELETE_ID: 'CABINETS_DELETE_ID',
  CABINETS_PATCH_ID: 'CABINETS_PATCH_ID',
  CABINETS_GET: 'CABINETS_GET',
  CABINETS_GET_COUNT: 'CABINETS_GET_COUNT',
  CABINETS_GET_V2_LIST: 'CABINETS_GET_V2_LIST',
  CABINETS_GET_ID_TSDATA: 'CABINETS_GET_ID_TSDATA',
  CABINETS_GET_ID_RAW_DATA: 'CABINETS_GET_ID_RAW_DATA',
  CABINETS_GET_ID_CHART_DATA: 'CABINETS_GET_ID_CHART_DATA',
  CABINETS_GET_ID_V2_REALTIME: 'CABINETS_GET_ID_V2_REALTIME',
  CABINETS_GET_ID_REALTIME: 'CABINETS_GET_ID_REALTIME',
  CABINETS_GET_ID_CONTROL_STATUS: 'CABINETS_GET_ID_CONTROL_STATUS',
  CABINETS_POST_ID_CONTROL: 'CABINETS_POST_ID_CONTROL',
  CABINETS_GET_ID_EMETER_EXPORT_DATA: 'CABINETS_GET_ID_EMETER_EXPORT_DATA',
  CABINETS_GET_ID_GENERAL_EXPORT_DATA: 'CABINETS_GET_ID_GENERAL_EXPORT_DATA',
  CABINETS_GET_ID_OUTGOINGFEEDER_EXPORT_DATA: 'CABINETS_GET_ID_OUTGOINGFEEDER_EXPORT_DATA',
  CABINETS_GET_ID_EMETERCOUNTER_EXPORT_DATA: 'CABINETS_GET_ID_EMETERCOUNTER_EXPORT_DATA',
  CABINETS_GET_ID_EXPORT_EMETERLOAD_30MINVIEW_DATA: 'CABINETS_GET_ID_EXPORT_EMETERLOAD_30MINVIEW_DATA',
  CABINETS_POST_ID_ATTACH_DEVICE: 'CABINETS_POST_ID_ATTACH_DEVICE',
  CABINETS_POST_ID_ACCEPT_DEVICE: 'CABINETS_POST_ID_ACCEPT_DEVICE',
  CABINETS_PATCH_ID_ALERTS_ALERTID: 'CABINETS_PATCH_ID_ALERTS_ALERTID',
  CABINETS_GET_ID_ANALYZE_ALERT: 'CABINETS_GET_ID_ANALYZE_ALERT',
  CABINETS_PATCH_ID_CONFIGURE: 'CABINETS_PATCH_ID_CONFIGURE',
  ALERTS_PATCH_ID: 'ALERTS_PATCH_ID',
  ALERTS_GET: 'ALERTS_GET',
  ALERTS_GET_COUNT: 'ALERTS_GET_COUNT',
  NOTIFICATIONS_GET: 'NOTIFICATIONS_GET',
  NOTIFICATIONS_GET_COUNT: 'NOTIFICATIONS_GET_COUNT',
  NOTIFICATIONS_POST_READS: 'NOTIFICATIONS_POST_READS',
  SETTINGS_GET_ID: 'SETTINGS_GET_ID',
  SETTINGS_PATCH_ID: 'SETTINGS_PATCH_ID',
  SETTINGS_GET: 'SETTINGS_GET',
  SETTINGS_GET_ALERT_TYPES: 'SETTINGS_GET_ALERT_TYPES',
  SETTINGS_GET_DATA_TYPES: 'SETTINGS_GET_DATA_TYPES',
  SETTINGS_GET_CATEGORIES: 'SETTINGS_GET_CATEGORIES',
  MONITORINGS_GET_CABINETGROUPS: 'MONITORINGS_GET_CABINETGROUPS',
  MONITORINGS_GET_ZONES: 'MONITORINGS_GET_ZONES',
  MONITORINGS_GET_WEBS: 'MONITORINGS_GET_WEBS',
  MONITORINGS_GET: 'MONITORINGS_GET',
  MONITORINGS_GET_COUNT: 'MONITORINGS_GET_COUNT',
  MONITORINGS_GET_BY_OPERATORS: 'MONITORINGS_GET_BY_OPERATORS',
  MONITORINGS_GET_BY_OPERATORS_COUNT: 'MONITORINGS_GET_BY_OPERATORS_COUNT',
  SYSTEMSETTINGS_GET_ID: 'SYSTEMSETTINGS_GET_ID',
  SYSTEMSETTINGS_PATCH_ID: 'SYSTEMSETTINGS_PATCH_ID',
  SYSTEMSETTINGS_GET: 'SYSTEMSETTINGS_GET',
  SYSTEMSETTINGS_GET_COUNT: 'SYSTEMSETTINGS_GET_COUNT',
  CHARTS_GET_ID: 'CHARTS_GET_ID',
  CHARTS_GET: 'CHARTS_GET',
  CHARTS_GET_COUNT: 'CHARTS_GET_COUNT'
}
export const apis = {

  // Queries cabinetOperatings of user.
  USERS_GET_ID_CABINETOPERATINGS: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/users/${id}/cabinetOperatings?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Counts cabinetOperatings of user.
  USERS_GET_ID_CABINETOPERATINGS_COUNT: ({ id, where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/users/${id}/cabinetOperatings/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // Find a model instance by {{id}} from the data source.
  USERS_GET_ID: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/users/${id}?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Delete a model instance by {{id}} from the data source.
  USERS_DELETE_ID: ({ id }) => ({
    method: 'DELETE',
    url: `${apiEndpoint}/api/users/${id}`
  }),
  // Update user information
  USERS_PATCH_ID: ({ id, body }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/users/${id}`,
    body
  }),
  // Find all instances of the model matched by filter from the data source.
  USERS_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/users?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Register a user account with email and password.
  USERS_POST: ({ body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users`,
    body
  }),
  // Count instances of the model matched by where from the data source.
  USERS_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/users/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // create FCM notification
  USERS_POST_ID_FCMS: ({ id, token, appId }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/${id}/fcms`,
    body: { token, appId }
  }),
  // Reset password for a user with email.
  USERS_POST_RESET: ({ email }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/reset`,
    body: { email }
  }),
  // Login a user with email and password.
  USERS_POST_LOGIN: ({ email, password, username }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/login`,
    body: { email, password, username }
  }),
  // Logout a user with access token.
  USERS_POST_LOGOUT: () => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/logout`
  }),
  // Confirm a user registration with identity verification token.
  USERS_GET_CONFIRM: ({ uid, token, redirect }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/users/confirm?${uid ? `uid=${encodeURIComponent(typeof uid === 'object' ? JSON.stringify(uid) : uid)}&` : ''}${token ? `token=${encodeURIComponent(typeof token === 'object' ? JSON.stringify(token) : token)}&` : ''}${redirect ? `redirect=${encodeURIComponent(typeof redirect === 'object' ? JSON.stringify(redirect) : redirect)}` : ''}`
  }),
  // Resend confirmation email
  USERS_POST_RESEND_CONFIRM_EMAIL: ({ email }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/resend-confirm-email`,
    body: { email }
  }),
  // User reset his/her password
  USERS_POST_ID_CHANGE_PASSWORD: ({ id, arg }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/${id}/change-password`,
    body: arg
  }),
  // Check user password
  USERS_POST_ID_CHECK_PASSWORD: ({ id, password }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/${id}/check-password`,
    body: { password }
  }),
  // Reset user's password via a password-reset token.
  USERS_POST_RESET_PASSWORD: ({ newPassword }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/reset-password`,
    body: { newPassword }
  }),
  // Suspend an user account
  USERS_POST_ID_SUSPEND: ({ id, suspend }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/${id}/suspend`,
    body: { suspend }
  }),
  // set account password
  USERS_POST_ID_SET_PASSWORD: ({ id, password }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/users/${id}/set-password`,
    body: { password }
  }),
  // Find a related item by id for cabinetGroups.
  ZONES_GET_ID_CABINETGROUPS_FK: ({ id, fk }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/zones/${id}/cabinetGroups/${fk}`
  }),
  // Queries cabinetGroups of zone.
  ZONES_GET_ID_CABINETGROUPS: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/zones/${id}/cabinetGroups?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // create new CabinetGroup
  ZONES_POST_ID_CABINETGROUPS: ({ id, body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/zones/${id}/cabinetGroups`,
    body
  }),
  // Create a new instance of the model and persist it into the data source.
  ZONES_POST: ({ data }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/zones`,
    body: data
  }),
  // Find all instances of the model matched by filter from the data source.
  ZONES_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/zones?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Find a model instance by {{id}} from the data source.
  ZONES_GET_ID: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/zones/${id}?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Delete a model instance by {{id}} from the data source.
  ZONES_DELETE_ID: ({ id }) => ({
    method: 'DELETE',
    url: `${apiEndpoint}/api/zones/${id}`
  }),
  // Patch attributes for a model instance and persist it into the data source.
  ZONES_PATCH_ID: ({ id, data }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/zones/${id}`,
    body: data
  }),
  // Count instances of the model matched by where from the data source.
  ZONES_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/zones/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // create new cabinet
  ZONES_POST_ID_CABINETS: ({ id, body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/zones/${id}/cabinets`,
    body
  }),
  // Get all cabinet groups of a cabinet group to moving
  ZONES_GET_ID_CABINETGROUPS_CABINETGROUPID_TOMOVING: ({ id, cabinetGroupId }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/zones/${id}/cabinetGroups/${cabinetGroupId}/toMoving`
  }),
  // update a CabinetGroup
  ZONES_PUT_ID_CABINETGROUPS_CABINETGROUPID: ({ id, cabinetGroupId, body }) => ({
    method: 'PUT',
    url: `${apiEndpoint}/api/zones/${id}/cabinetGroups/${cabinetGroupId}`,
    body
  }),
  // remove a cabinetGroup in a zone
  ZONES_DELETE_ID_CABINETGROUPS_CABINETGROUPID: ({ id, cabinetGroupId }) => ({
    method: 'DELETE',
    url: `${apiEndpoint}/api/zones/${id}/cabinetGroups/${cabinetGroupId}`
  }),
  // dashboard statistics
  ZONES_GET_ID_STATS: ({ id }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/zones/${id}/stats`
  }),
  // create new user
  ZONES_POST_ID_USERS: ({ id, body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/zones/${id}/users`,
    body
  }),
  // Fetches belongsTo relation broker.
  DEVICES_GET_ID_BROKER: ({ id, refresh }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/devices/${id}/broker?${refresh ? `refresh=${encodeURIComponent(typeof refresh === 'object' ? JSON.stringify(refresh) : refresh)}` : ''}`
  }),
  // Creates a new instance in cabinet of this model.
  DEVICES_POST_ID_CABINET: ({ id, data }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/devices/${id}/cabinet`,
    body: data
  }),
  // Update cabinet of this model.
  DEVICES_PUT_ID_CABINET: ({ id, data }) => ({
    method: 'PUT',
    url: `${apiEndpoint}/api/devices/${id}/cabinet`,
    body: data
  }),
  // Deletes cabinet of this model.
  DEVICES_DELETE_ID_CABINET: ({ id }) => ({
    method: 'DELETE',
    url: `${apiEndpoint}/api/devices/${id}/cabinet`
  }),
  // Find a model instance by {{id}} from the data source.
  DEVICES_GET_ID: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/devices/${id}?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Patch attributes for a model instance and persist it into the data source.
  DEVICES_PATCH_ID: ({ id, data }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/devices/${id}`,
    body: data
  }),
  // Find all instances of the model matched by filter from the data source.
  DEVICES_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/devices?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Count instances of the model matched by where from the data source.
  DEVICES_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/devices/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // send fota command to device
  DEVICES_POST_TRIGGER_FOTA: ({ deviceId }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/devices/trigger-fota`,
    body: { deviceId }
  }),
  // update note for an alert
  DEVICES_PUT_DEVICEID_DEVICEFIRMWAREVERSION: ({ deviceId, body }) => ({
    method: 'PUT',
    url: `${apiEndpoint}/api/devices/${deviceId}/devicefirmwareversion`,
    body
  }),
  // Fetches belongsTo relation device.
  CABINETS_GET_ID_DEVICE: ({ id, refresh }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/device?${refresh ? `refresh=${encodeURIComponent(typeof refresh === 'object' ? JSON.stringify(refresh) : refresh)}` : ''}`
  }),
  // Fetches belongsTo relation attachByUser.
  CABINETS_GET_ID_ATTACHBYUSER: ({ id, refresh }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/attachByUser?${refresh ? `refresh=${encodeURIComponent(typeof refresh === 'object' ? JSON.stringify(refresh) : refresh)}` : ''}`
  }),
  // Find a model instance by {{id}} from the data source.
  CABINETS_GET_ID: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Delete a model instance by {{id}} from the data source.
  CABINETS_DELETE_ID: ({ id }) => ({
    method: 'DELETE',
    url: `${apiEndpoint}/api/cabinets/${id}`
  }),
  // Update a cabinet
  CABINETS_PATCH_ID: ({ id, body }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/cabinets/${id}`,
    body
  }),
  // Find all instances of the model matched by filter from the data source.
  CABINETS_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Count instances of the model matched by where from the data source.
  CABINETS_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // list API v2
  CABINETS_GET_V2_LIST: ({ zoneId, cabinetGroupId, filterStr, userId, includeAncestorCabinetGroup, limit, skip }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/v2/list?${zoneId ? `zoneId=${encodeURIComponent(typeof zoneId === 'object' ? JSON.stringify(zoneId) : zoneId)}&` : ''}${cabinetGroupId ? `cabinetGroupId=${encodeURIComponent(typeof cabinetGroupId === 'object' ? JSON.stringify(cabinetGroupId) : cabinetGroupId)}&` : ''}${filterStr ? `filterStr=${encodeURIComponent(typeof filterStr === 'object' ? JSON.stringify(filterStr) : filterStr)}&` : ''}${userId ? `userId=${encodeURIComponent(typeof userId === 'object' ? JSON.stringify(userId) : userId)}&` : ''}${includeAncestorCabinetGroup ? `includeAncestorCabinetGroup=${encodeURIComponent(typeof includeAncestorCabinetGroup === 'object' ? JSON.stringify(includeAncestorCabinetGroup) : includeAncestorCabinetGroup)}&` : ''}${limit ? `limit=${encodeURIComponent(typeof limit === 'object' ? JSON.stringify(limit) : limit)}&` : ''}${skip ? `skip=${encodeURIComponent(typeof skip === 'object' ? JSON.stringify(skip) : skip)}` : ''}`
  }),
  // get timeserial data
  CABINETS_GET_ID_TSDATA: ({ id, start, end, type, limit }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/tsdata?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}&` : ''}${type ? `type=${encodeURIComponent(typeof type === 'object' ? JSON.stringify(type) : type)}&` : ''}${limit ? `limit=${encodeURIComponent(typeof limit === 'object' ? JSON.stringify(limit) : limit)}` : ''}`
  }),
  // get raw data
  CABINETS_GET_ID_RAW_DATA: ({ id, start, end, category, fields, channels, skip, limit, timeOrder, usedFor }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/raw-data?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}&` : ''}${category ? `category=${encodeURIComponent(typeof category === 'object' ? JSON.stringify(category) : category)}&` : ''}${fields ? `fields=${encodeURIComponent(typeof fields === 'object' ? JSON.stringify(fields) : fields)}&` : ''}${channels ? `channels=${encodeURIComponent(typeof channels === 'object' ? JSON.stringify(channels) : channels)}&` : ''}${skip ? `skip=${encodeURIComponent(typeof skip === 'object' ? JSON.stringify(skip) : skip)}&` : ''}${limit ? `limit=${encodeURIComponent(typeof limit === 'object' ? JSON.stringify(limit) : limit)}&` : ''}${timeOrder ? `timeOrder=${encodeURIComponent(typeof timeOrder === 'object' ? JSON.stringify(timeOrder) : timeOrder)}&` : ''}${usedFor ? `usedFor=${encodeURIComponent(typeof usedFor === 'object' ? JSON.stringify(usedFor) : usedFor)}` : ''}`
  }),
  // get chart data
  CABINETS_GET_ID_CHART_DATA: ({ id, chartId, start, end, channel }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/chart-data?${chartId ? `chartId=${encodeURIComponent(typeof chartId === 'object' ? JSON.stringify(chartId) : chartId)}&` : ''}${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}&` : ''}${channel ? `channel=${encodeURIComponent(typeof channel === 'object' ? JSON.stringify(channel) : channel)}` : ''}`
  }),
  // get cabinet realtime data include: categories, alerts, device status
  CABINETS_GET_ID_V2_REALTIME: ({ id, categories }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/v2/realtime?${categories ? `categories=${encodeURIComponent(typeof categories === 'object' ? JSON.stringify(categories) : categories)}` : ''}`
  }),
  // get cabinet realtime data include: realtimedatas, alerts, online status
  CABINETS_GET_ID_REALTIME: ({ id }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/realtime`
  }),
  // get latest control device status
  CABINETS_GET_ID_CONTROL_STATUS: ({ id }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/control-status`
  }),
  // control the device
  CABINETS_POST_ID_CONTROL: ({ id, body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/cabinets/${id}/control`,
    body
  }),
  // get emeter data
  CABINETS_GET_ID_EMETER_EXPORT_DATA: ({ id, start, end }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/emeter-export-data?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}` : ''}`
  }),
  // export cabinet general data
  CABINETS_GET_ID_GENERAL_EXPORT_DATA: ({ id, start, end }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/general-export-data?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}` : ''}`
  }),
  // export outgoing feeder data
  CABINETS_GET_ID_OUTGOINGFEEDER_EXPORT_DATA: ({ id, start, end, channels }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/outgoingfeeder-export-data?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}&` : ''}${channels ? `channels=${encodeURIComponent(typeof channels === 'object' ? JSON.stringify(channels) : channels)}` : ''}`
  }),
  // export emeter counter data
  CABINETS_GET_ID_EMETERCOUNTER_EXPORT_DATA: ({ id, start, end }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/emetercounter-export-data?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}` : ''}`
  }),
  // export emeter load 30 mint view data
  CABINETS_GET_ID_EXPORT_EMETERLOAD_30MINVIEW_DATA: ({ id, start, end }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/export-emeterload-30minview-data?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}` : ''}`
  }),
  // attach a device to a cabinet by zone-operator
  CABINETS_POST_ID_ATTACH_DEVICE: ({ id, body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/cabinets/${id}/attach-device`,
    body
  }),
  // accept attaching device
  CABINETS_POST_ID_ACCEPT_DEVICE: ({ id, body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/cabinets/${id}/accept-device`,
    body
  }),
  // update note for an alert
  CABINETS_PATCH_ID_ALERTS_ALERTID: ({ id, alertId, body }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/cabinets/${id}/alerts/${alertId}`,
    body
  }),
  // analyze alerts in a cabinet
  CABINETS_GET_ID_ANALYZE_ALERT: ({ id, start, end }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/cabinets/${id}/analyze-alert?${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}` : ''}`
  }),
  // cabinet device config
  CABINETS_PATCH_ID_CONFIGURE: ({ id, body }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/cabinets/${id}/configure`,
    body
  }),
  // Patch attributes for a model instance and persist it into the data source.
  ALERTS_PATCH_ID: ({ id, data }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/alerts/${id}`,
    body: data
  }),
  // find all alerts
  ALERTS_GET: ({ filter, start, end }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/alerts?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}&` : ''}${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}` : ''}`
  }),
  // count alerts
  ALERTS_GET_COUNT: ({ where, start, end }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/alerts/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}&` : ''}${start ? `start=${encodeURIComponent(typeof start === 'object' ? JSON.stringify(start) : start)}&` : ''}${end ? `end=${encodeURIComponent(typeof end === 'object' ? JSON.stringify(end) : end)}` : ''}`
  }),
  // Find all instances of the model matched by filter from the data source.
  NOTIFICATIONS_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/notifications?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Count instances of the model matched by where from the data source.
  NOTIFICATIONS_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/notifications/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // batch update read/unread notification
  NOTIFICATIONS_POST_READS: ({ body }) => ({
    method: 'POST',
    url: `${apiEndpoint}/api/notifications/reads`,
    body
  }),
  // Find a model instance by {{id}} from the data source.
  SETTINGS_GET_ID: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/settings/${id}?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Patch attributes for a model instance and persist it into the data source.
  SETTINGS_PATCH_ID: ({ id, data }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/settings/${id}`,
    body: data
  }),
  // Find all instances of the model matched by filter from the data source.
  SETTINGS_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/settings?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // list all alert types
  SETTINGS_GET_ALERT_TYPES: () => ({
    method: 'GET',
    url: `${apiEndpoint}/api/settings/alert-types`
  }),
  // list all data types
  SETTINGS_GET_DATA_TYPES: () => ({
    method: 'GET',
    url: `${apiEndpoint}/api/settings/data-types`
  }),
  // list all data types in categories
  SETTINGS_GET_CATEGORIES: () => ({
    method: 'GET',
    url: `${apiEndpoint}/api/settings/categories`
  }),
  // get cabinet group monitorings.
  MONITORINGS_GET_CABINETGROUPS: ({ zoneId, cabinetGroupId, filterStr, userId, limit, skip }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/monitorings/cabinetGroups?${zoneId ? `zoneId=${encodeURIComponent(typeof zoneId === 'object' ? JSON.stringify(zoneId) : zoneId)}&` : ''}${cabinetGroupId ? `cabinetGroupId=${encodeURIComponent(typeof cabinetGroupId === 'object' ? JSON.stringify(cabinetGroupId) : cabinetGroupId)}&` : ''}${filterStr ? `filterStr=${encodeURIComponent(typeof filterStr === 'object' ? JSON.stringify(filterStr) : filterStr)}&` : ''}${userId ? `userId=${encodeURIComponent(typeof userId === 'object' ? JSON.stringify(userId) : userId)}&` : ''}${limit ? `limit=${encodeURIComponent(typeof limit === 'object' ? JSON.stringify(limit) : limit)}&` : ''}${skip ? `skip=${encodeURIComponent(typeof skip === 'object' ? JSON.stringify(skip) : skip)}` : ''}`
  }),
  // get cabinet group monitorings.
  MONITORINGS_GET_ZONES: ({ zoneId, filterStr, limit, skip }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/monitorings/zones?${zoneId ? `zoneId=${encodeURIComponent(typeof zoneId === 'object' ? JSON.stringify(zoneId) : zoneId)}&` : ''}${filterStr ? `filterStr=${encodeURIComponent(typeof filterStr === 'object' ? JSON.stringify(filterStr) : filterStr)}&` : ''}${limit ? `limit=${encodeURIComponent(typeof limit === 'object' ? JSON.stringify(limit) : limit)}&` : ''}${skip ? `skip=${encodeURIComponent(typeof skip === 'object' ? JSON.stringify(skip) : skip)}` : ''}`
  }),
  // monitoring API for web list/map view.
  MONITORINGS_GET_WEBS: ({ zoneId, cabinetGroupId, filterStr, userId, includeTopAlert, includeAncestorCabinetGroup, limit, skip }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/monitorings/webs?${zoneId ? `zoneId=${encodeURIComponent(typeof zoneId === 'object' ? JSON.stringify(zoneId) : zoneId)}&` : ''}${cabinetGroupId ? `cabinetGroupId=${encodeURIComponent(typeof cabinetGroupId === 'object' ? JSON.stringify(cabinetGroupId) : cabinetGroupId)}&` : ''}${filterStr ? `filterStr=${encodeURIComponent(typeof filterStr === 'object' ? JSON.stringify(filterStr) : filterStr)}&` : ''}${userId ? `userId=${encodeURIComponent(typeof userId === 'object' ? JSON.stringify(userId) : userId)}&` : ''}${includeTopAlert ? `includeTopAlert=${encodeURIComponent(typeof includeTopAlert === 'object' ? JSON.stringify(includeTopAlert) : includeTopAlert)}&` : ''}${includeAncestorCabinetGroup ? `includeAncestorCabinetGroup=${encodeURIComponent(typeof includeAncestorCabinetGroup === 'object' ? JSON.stringify(includeAncestorCabinetGroup) : includeAncestorCabinetGroup)}&` : ''}${limit ? `limit=${encodeURIComponent(typeof limit === 'object' ? JSON.stringify(limit) : limit)}&` : ''}${skip ? `skip=${encodeURIComponent(typeof skip === 'object' ? JSON.stringify(skip) : skip)}` : ''}`
  }),
  // get cabinet monitorings
  MONITORINGS_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/monitorings?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // count cabinet monitorings
  MONITORINGS_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/monitorings/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // get cabinet monitorings. Filter by alert type, online status, has device
  MONITORINGS_GET_BY_OPERATORS: ({ nameOrDeviceId, alertType, online, hasDevice, hasAlert, hasPanic, limit, offset }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/monitorings/by-operators?${nameOrDeviceId ? `nameOrDeviceId=${encodeURIComponent(typeof nameOrDeviceId === 'object' ? JSON.stringify(nameOrDeviceId) : nameOrDeviceId)}&` : ''}${alertType ? `alertType=${encodeURIComponent(typeof alertType === 'object' ? JSON.stringify(alertType) : alertType)}&` : ''}${online ? `online=${encodeURIComponent(typeof online === 'object' ? JSON.stringify(online) : online)}&` : ''}${hasDevice ? `hasDevice=${encodeURIComponent(typeof hasDevice === 'object' ? JSON.stringify(hasDevice) : hasDevice)}&` : ''}${hasAlert ? `hasAlert=${encodeURIComponent(typeof hasAlert === 'object' ? JSON.stringify(hasAlert) : hasAlert)}&` : ''}${hasPanic ? `hasPanic=${encodeURIComponent(typeof hasPanic === 'object' ? JSON.stringify(hasPanic) : hasPanic)}&` : ''}${limit ? `limit=${encodeURIComponent(typeof limit === 'object' ? JSON.stringify(limit) : limit)}&` : ''}${offset ? `offset=${encodeURIComponent(typeof offset === 'object' ? JSON.stringify(offset) : offset)}` : ''}`
  }),
  // count cabinet monitorings
  MONITORINGS_GET_BY_OPERATORS_COUNT: ({ nameOrDeviceId, alertType, online, hasDevice, hasAlert, hasPanic }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/monitorings/by-operators/count?${nameOrDeviceId ? `nameOrDeviceId=${encodeURIComponent(typeof nameOrDeviceId === 'object' ? JSON.stringify(nameOrDeviceId) : nameOrDeviceId)}&` : ''}${alertType ? `alertType=${encodeURIComponent(typeof alertType === 'object' ? JSON.stringify(alertType) : alertType)}&` : ''}${online ? `online=${encodeURIComponent(typeof online === 'object' ? JSON.stringify(online) : online)}&` : ''}${hasDevice ? `hasDevice=${encodeURIComponent(typeof hasDevice === 'object' ? JSON.stringify(hasDevice) : hasDevice)}&` : ''}${hasAlert ? `hasAlert=${encodeURIComponent(typeof hasAlert === 'object' ? JSON.stringify(hasAlert) : hasAlert)}&` : ''}${hasPanic ? `hasPanic=${encodeURIComponent(typeof hasPanic === 'object' ? JSON.stringify(hasPanic) : hasPanic)}` : ''}`
  }),
  // Find a model instance by {{id}} from the data source.
  SYSTEMSETTINGS_GET_ID: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/systemsettings/${id}?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // update a systemsetting
  SYSTEMSETTINGS_PATCH_ID: ({ id, value }) => ({
    method: 'PATCH',
    url: `${apiEndpoint}/api/systemsettings/${id}`,
    body: { value }
  }),
  // Find all instances of the model matched by filter from the data source.
  SYSTEMSETTINGS_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/systemsettings?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Count instances of the model matched by where from the data source.
  SYSTEMSETTINGS_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/systemsettings/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  }),
  // Find a model instance by {{id}} from the data source.
  CHARTS_GET_ID: ({ id, filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/charts/${id}?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Find all instances of the model matched by filter from the data source.
  CHARTS_GET: ({ filter }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/charts?${filter ? `filter=${encodeURIComponent(typeof filter === 'object' ? JSON.stringify(filter) : filter)}` : ''}`
  }),
  // Count instances of the model matched by where from the data source.
  CHARTS_GET_COUNT: ({ where }) => ({
    method: 'GET',
    url: `${apiEndpoint}/api/charts/count?${where ? `where=${encodeURIComponent(typeof where === 'object' ? JSON.stringify(where) : where)}` : ''}`
  })
}
