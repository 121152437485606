const ENTITY = 'cabinet'
const actions = {
  // SET
  SET_STATE: `${ENTITY}/SET_STATE`,
  SET_LIST_STATE: `${ENTITY}/SET_LIST_STATE`,
  SET_CONFIGURE: `${ENTITY}/SET_CONFIGURE`,
  SET_CONFIGURE_STATE: `${ENTITY}/SET_CONFIGURE_STATE`,
  SET_CONTROL_STATUS: `${ENTITY}/SET_CONTROL_STATUS`,
  SET_CONTROL_STATUS_POLLING: `${ENTITY}/SET_CONTROL_STATUS_POLLING`,
  SET_SHORT_LIST: `${ENTITY}/SET_SHORT_LIST`,
  SET_SHORT_LIST_SEARCH: `${ENTITY}/SET_SHORT_LIST_SEARCH`,

  // GET
  COUNT: `${ENTITY}/COUNT`,
  LIST: `${ENTITY}/LIST`,
  LISTV2: `${ENTITY}/LISTV2`,
  SHORT_LIST: `${ENTITY}/SHORT_LIST`,
  SHORT_LIST_SEARCH: `${ENTITY}/SHORT_LIST_SEARCH`,
  DETAIL: `${ENTITY}/DETAIL`,
  CREATE: `${ENTITY}/CREATE`,
  UPDATE: `${ENTITY}/UPDATE`,
  DELETE: `${ENTITY}/DELETE`,
  ACCEPT: `${ENTITY}/ACCEPT`,
  ANALYZE_ALERT: `${ENTITY}/ANALYZE_ALERT`,
  CONTROL_STATE: `${ENTITY}/CONTROL_STATE`,
  CONTROL_STATE_POLLING: `${ENTITY}/CONTROL_STATE_POLLING`,
  CONTROL: `${ENTITY}/CONTROL`,
  RAW_DATA: `${ENTITY}/RAW_DATA`,
  RAW_DATA_CHART_30M: `${ENTITY}/RAW_DATA_CHART_30M`,
  RAW_DATA_GRID_30M: `${ENTITY}/RAW_DATA_GRID_30M`,
  CHART_DATA: `${ENTITY}/CHART_DATA`,
  EXPORT_GENERAL_DATA: `${ENTITY}/EXPORT_GENERAL_DATA`,
  EXPORT_OUTGOINGFEEDER_DATA: `${ENTITY}/EXPORT_OUTGOINGFEEDER_DATA`,
  EXPORT_EMETER_DATA: `${ENTITY}/EXPORT_EMETER_DATA`,
  EXPORT_EMETER_COUNTER_DATA: `${ENTITY}/EXPORT_EMETER_COUNTER_DATA`,
  EXPORT_EMETER_30M_DATA: `${ENTITY}/EXPORT_EMETER_30M_DATA`,
  CLEAR_EXPORTED_DATA: `${ENTITY}/CLEAR_EXPORTED_DATA`
}

export default actions
