import React from 'react'
import { Menu, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'
import style from './notifications/style.module.scss'
import { history } from 'index'
import moment from 'moment'
import './style.scss'

const Actions = ({ notification, dispatch }) => {
  let { totalUnread, listUnread } = notification
  const notificationList = listUnread.map(item => {
    const { template, vars } = item
    const { var1 = {}, var2 = {}, var3 = {}, cabinet = {} } = vars
    let message = '<i class="i_warning_18 ico25"></i><div class="flex-wrap">' + template + '</div>'
    message = message.replace('<%= var1 %>', ` <span class='text-red space-2'>${var1.name}</span>`)
    message = message.replace('<%= var2 %>', ` <span class='text-red space-2'>${var2.name}</span>`)
    message = message.replace('<%= var3 %>', ` <span class='text-red space-2'>${var3.name}</span>`)
    message = message.replace('<%= cabinet %>', ` <span class='text-red space-2'>${cabinet.name}</span>`)
    return { ...item, description: message }
  })
  const read = (item) => {
    dispatch({
      type: 'notification/READ',
      payload: {
        body: {
          "read": true,
          "notifications": [
            item.id
          ]
        }
      }
    })
    history.push(`/cabinets/${item.cabinetId}`)
  }


  const menu = (
    <Menu selectable={false}>
      <Menu.Item className='dropdown-title'>
        <strong>
          Thông báo
        </strong>
      </Menu.Item>
      <Menu.Item className='warning-list'>
        {notificationList.map(x => (
          <div
            key={x.id}
            className="ant-list-item warning-unread display-flex"
            onClick={() => read(x)}
          >
            <div className={`warning-item ${style.head}`} style={{ cursor: 'pointer' }}>
              <p className={style.title} />
              <div className='ds' dangerouslySetInnerHTML={{ __html: x.description }} />
              &nbsp;
              <time className={style.time}>{moment(x.created).format('YYYY-MM-DD HH:mm')}</time>
            </div>
          </div>
        ))}
        {notificationList.length === 0 && (
          <span>Không có thông báo mới.</span>
        )}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to='/notifications'><i className='i_right_arrow'></i>Xem tất cả thông báo</Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} >
      <div className='topbar-header'>
        <div className={`ico-dropdown ${styles.dropdown}`}>
          <div className=" ant-dropdown-trigger">
            <i className={`${styles.icon} fe fe-bell`} />
            {totalUnread > 0 && <span className='label-count'>{totalUnread}</span>}
          </div>
        </div>
      </div>
    </Dropdown>

  )
}

export default Actions
