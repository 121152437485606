import moment from 'moment'

export const HOCHIMINH_CENTER = [10.7682231, 106.7045727]
export const WATTAGE = [
  { label: '50KVA', value: 50 },
  { label: '100KVA', value: 100 },
  { label: '150KVA', value: 150 },
  { label: '160KVA', value: 160 },
  { label: '200KVA', value: 200 },
  { label: '250KVA', value: 250 },
  { label: '260KVA', value: 260 },
  { label: '320KVA', value: 320 },
  { label: '400KVA', value: 400 },
  { label: '500KVA', value: 500 },
  { label: '560KVA', value: 560 },
  { label: '630KVA', value: 630 },
  { label: '750KVA', value: 750 },
  { label: '800KVA', value: 800 },
  { label: '1000KVA', value: 1000 },
  { label: '1250KVA', value: 1250 },
  { label: '1500KVA', value: 1500 },
  { label: '2000KVA', value: 2000 },
  { label: '2500KVA', value: 2500 },
  { label: '3200KVA', value: 3200 }
]
export const ROLE = {
  ADMIN: 'admin',
  MANAGER: 'zone-manager',
  OPERATOR: 'zone-operator',
  // compatible
  admin: 'Quản trị hệ thống',
  'zone-manager': 'Quản lý khu vực',
  'zone-operator': 'Đơn vị vận hành'
}
export const CABINET_STATUS = {
  normal: 'Bình thường',
  alert: 'Đang bị sự cố',
  warning: 'Đang cảnh báo',
  offline: 'Mất kết nối'
}
export const RAW_DATA_TYPE = {
  GridView: 'gridview',
  Chart: 'chart'
}
export const RAW_DATA_CATEGORY = {
  General: 'general',
  OutGoingFeeder: 'outgoingFeeder',
  Emeter: 'emeter',
  EmeterCounter: 'emeterCounter'
}
export const RAW_DATA_FIELDS = {
  General: [
    't', 'P', 'U1', 'U2', 'U3', 'I1', 'I2', 'I3',
    'IN', 'P1', 'P2', 'P3', 'Q1', 'Q2', 'Q3', 'P12', 'P23', 'P31', 'I12', 'I23', 'I31', 'PF1', 'PF2', 'PF3', 'TS', 'FRE', 'TB', 'BA'
  ],
  OutGoingFeeder: ['channel', 't', 'UA', 'UB', 'UC', 'IA', 'IB',
    'IC', 'PA', 'PB', 'PC', 'PFA', 'PFB', 'PFC'
  ],
  Emeter: [
    'UA', 'UB', 'UC',
    'IA', 'IB', 'IC',
    'PSA', 'PSB', 'PSC',
    'PFA', 'PFB', 'PFC',
    'FREA', 'FREB', 'FREC'
  ]
}
export const CHART_TYPE = {
  // general
  ActivePower: 'activePower', // Công suất - general
  Amperage: 'amperage', // "Dòng Điện
  Frequency: 'frequency', // "Tần Số Điện"
  PowerFactor: 'powerFactor', // "Hệ Số Công Suất"
  ReactivePower: 'reactivePower', // "Công Suất Phản Kháng"
  // PhaseShift: 'phaseShift', // "Góc Lệch Pha"
  Sensor: 'sensor', // "Cảm Biến"
  Voltage: 'voltage', // "Điện Áp"
  // outgoingFeeder
  OutgoingFeederVoltage: 'outgoingFeederVoltage', // "Điện Áp"
  OutgoingFeederAmperage: 'outgoingFeederAmperage', // "Dòng Điện"
  OutgoingFeederActivePower: 'outgoingFeederActivePower', // "Công Suất"
  OutgoingFeederPowerFactor: 'outgoingFeederPowerFactor', // "Hệ Số Công Suất"
  // emeter
  EmeterAmperage: 'emeterAmperage', // "Dòng Điện"
  EmeterVoltage: 'emeterVoltage', // "Điện Áp"
  EmeterPowerFactor: 'emeterPowerFactor', // "Hệ Số Công Suất"
  EmeterFrequency: 'emeterFrequency', // "Tần Số Điện"
  // Phụ tải 30'
  emeterLoad30MinEnergyOut: 'emeterLoad30MinEnergyOut', // "P tổng giao (kW)"
  emeterLoad30MinEnergyIn: 'emeterLoad30MinEnergyIn', // "P tổng nhận (kW)"
  emeterLoad30MinReactiveEnergyOut: 'emeterLoad30MinReactiveEnergyOut', // Q tổng giao (kW)"
  emeterLoad30MinReactiveEnergyIn: 'emeterLoad30MinReactiveEnergyIn' // Q tổng nhận (kW)"
}
export const TIME_SECOND_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const TIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FILE_FORMAT = 'DD-MM-YYYY'
export const SHORT_DATE_FORMAT = 'DD/MM HH:mm'

export const DATE_PICKER_OPTIONS = {
  opens: 'center',
  drops: 'down',
  timePicker: true,
  minYear: 2000,
  maxYear: 2038,
  maxSpan: {
    months: 1
  },
  // minDate: 1,
  maxDate: moment().endOf('day'),
  locale: {
    format: TIME_FORMAT,
    separator: ' - ',
    applyLabel: 'Chọn',
    cancelLabel: 'Hủy',
    fromLabel: 'Từ',
    toLabel: 'Đến',
    customRangeLabel: 'Tùy chọn',
    daysOfWeek: [
      'CN',
      'Hai',
      'Ba',
      'Tư',
      'Năm',
      'Sáu',
      'Báy'
    ],
    monthNames: [
      'Tháng 1',
      'Tháng 2',
      'Tháng 3',
      'Tháng 4',
      'Tháng 5',
      'Tháng 6',
      'Tháng 7',
      'Tháng 8',
      'Tháng 9',
      'Tháng 10',
      'Tháng 11',
      'Tháng 12'
    ],
    firstDay: 1
  }
}
