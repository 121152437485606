import React from 'react'
import { Input, Form } from 'antd'
import Actions from './Actions'
import UserMenu from './UserMenu'
import { history } from 'index'
import style from './style.module.scss'
import './customize_topBar.scss'

const { Search } = Input;
const TopBar = ({ notification, mainTitle, subTitle, dispatch }) => {
  return (
    <div className={`topbar-content ${style.topbar}`}>
      <div className="pull-left company-brand col-lg-6 col-sm-12">
        <img className="" src="/resources/dtms_img/DTMS_Logo_Ver2.0.png" alt='topbar' />
        <div className="company-name">
          <h4>{mainTitle}</h4>
          <h5>{subTitle}</h5>
        </div>
      </div>
      <div className="pull-right top-right col-lg-6 col-sm-12">
        <div className="topbar-right display-flex">
          <div className="search-box">
            <Form autoComplete='off'>
              <Search allowClear name="dtms-search-box" id='dtms-search-box' type="text" autoComplete="off" placeholder="Tìm kiếm trạm biến áp..." onSearch={value => history.push(`/cabinets?name=${value}`)}
                size="large" />
            </Form>
          </div>
          <div className="d-sm-block">
            <Actions notification={notification} dispatch={dispatch} />
          </div>
          <div className="">
            <UserMenu />
          </div>
        </div>
      </div>
    </div >
  )
}

export default TopBar
