import { listApi, detailApi } from '../index'
import apiClient from 'services/axios'

const API = {
  count: '/api/users/count', // count all cabinet data from backend API for paging
  list: '/api/users', // list all data to list, search, filter, sort.
  detail: '/api/users/{0}',
  create: '/api/zones/{0}/users',
  update: '/api/users/{0}', // api/users/:id
  delete: '/api/users/{0}' // api/cabinets/:id
}
export const list = async (limit = 10, skip = 0, username, zone, role) => {
  return listApi(API, limit, skip, username, zone, role)
}
export const detail = async (id) => {
  return detailApi(API, id)
}
export const create = async (zoneId, { name, username, password, description }) => {
  const response = await apiClient.post(API.create.replace('{0}', zoneId), { name, username, password, description })
  if (response) {
    return response.data
  }
}
export const update = async (id, data) => {
  const response = await apiClient.patch(API.update.replace('{0}', id), data)
  return response.data
}
export const destroy = async (id, data) => {
  const response = await apiClient.delete(API.update.replace('{0}', id), data)
  return response.data
  // return destroyApi(API, id)
}
export const changePassword = async (id, body) => {
  const response = await apiClient.post(`/api/users/${id}/change-password`, body)
  if (!response || !response.data) {
    return
  }
  return response.data
}

export const setPassword = async (id, body) => {
  const response = await apiClient.post(`/api/users/${id}/set-password`, body)
  if (!response || !response.data) {
    return
  }
  return response.data
}
