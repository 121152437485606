import { lazy } from 'react'
import { ROLE } from 'constant'
export const entity = {
  warnings: 'warnings',
  cabinets: 'cabinets',
  cabinetGroups: 'cabinet-groups',
  zones: 'zones',
  accounts: 'accounts',
  monitorings: 'monitorings',
  settings: 'settings',
  notifications: 'notifications',
  help: 'tro-giup'
}
export const common = {
  home: 'home',
  history: 'history',
  create: 'create',
  update: 'update',
  status: 'status',
  configure: 'configure',
  control: 'control'
}
export const routes = [
  // Dashboards
  {
    path: `/${common.home}`,
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  // warning
  {
    path: `/${entity.warnings}`,
    Component: lazy(() => import('pages/warning')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]

  },
  {
    path: `/${entity.warnings}/${common.history}`,
    Component: lazy(() => import('pages/warning/history')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.notifications}`,
    Component: lazy(() => import('pages/notifications')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  // cabinet
  {
    path: `/${entity.cabinets}`,
    Component: lazy(() => import('pages/cabinet')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.cabinets}/${common.create}`,
    Component: lazy(() => import('pages/cabinet/create')),
    exact: true,
    roles: [ROLE.MANAGER]
  },
  {
    path: `/${entity.cabinets}/${common.status}`,
    Component: lazy(() => import('pages/cabinet/status')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.cabinets}/:id/${common.configure}`,
    Component: lazy(() => import('pages/cabinet/setting')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.cabinets}/:id`,
    Component: lazy(() => import('pages/cabinet/detail')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.cabinets}/:id/${common.update}`,
    Component: lazy(() => import('pages/cabinet/update')),
    exact: true,
    roles: [ROLE.MANAGER]
  },
  // cabinetGroup
  {
    path: `/${entity.cabinetGroups}`,
    Component: lazy(() => import('pages/cabinet-group')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.cabinetGroups}/${common.create}`,
    Component: lazy(() => import('pages/cabinet-group/create')),
    exact: true,
    roles: [ROLE.MANAGER]
  },
  {
    path: `/${entity.cabinetGroups}/:id`,
    Component: lazy(() => import('pages/cabinet-group/detail')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.cabinetGroups}/:id/${common.update}`,
    Component: lazy(() => import('pages/cabinet-group/update')),
    exact: true,
    roles: [ROLE.MANAGER]
  },
  // Zone
  {
    path: `/${entity.zones}`,
    Component: lazy(() => import('pages/zone')),
    exact: true,
    roles: [ROLE.ADMIN]
  },
  {
    path: `/${entity.zones}/${common.create}`,
    Component: lazy(() => import('pages/zone/create')),
    exact: true,
    roles: [ROLE.ADMIN]
  },
  {
    path: `/${entity.zones}/:id`,
    Component: lazy(() => import('pages/zone/detail')),
    exact: true,
    roles: [ROLE.ADMIN]
  },
  {
    path: `/${entity.zones}/:id/${common.update}`,
    Component: lazy(() => import('pages/zone/update')),
    exact: true,
    roles: [ROLE.ADMIN]
  },
  /// //////USER//////////
  {
    path: `/${entity.accounts}`,
    Component: lazy(() => import('pages/account')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER]
  },

  {
    path: `/${entity.accounts}/${common.create}`, // phai dat truoc users/:id
    Component: lazy(() => import('pages/account/create')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER]
  },
  {
    path: `/${entity.accounts}/:id`,
    Component: lazy(() => import('pages/account/detail')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER]
  },
  {
    path: `/${entity.accounts}/:id/${common.update}`,
    Component: lazy(() => import('pages/account/update')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER]
  },
  /// ////SETTING///////
  {
    path: `/${entity.settings}`,
    Component: lazy(() => import('pages/setting')),
    exact: true,
    roles: [ROLE.ADMIN]
  },
  /// //MONITORING///////////
  {
    path: `/${entity.monitorings}`,
    Component: lazy(() => import('pages/monitoring')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.monitorings}/grid/:zoneId`,
    Component: lazy(() => import('pages/monitoring/grid')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.monitorings}/list`,
    Component: lazy(() => import('pages/monitoring/list')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.monitorings}/map`,
    Component: lazy(() => import('pages/monitoring/map')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.monitorings}/:id`,
    Component: lazy(() => import('pages/monitoring/detail')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.monitorings}/:id/electrical-power`,
    Component: lazy(() => import('pages/monitoring/electrical-power')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.monitorings}/:id/${common.control}`,
    Component: lazy(() => import('pages/monitoring/control')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  {
    path: `/${entity.help}`,
    Component: lazy(() => import('pages/help')),
    exact: true,
    roles: [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR]
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
    roles: ['*']
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
    roles: ['*']
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
    roles: ['*']
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
    roles: ['*']
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
    roles: ['*']
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
    roles: ['*']
  }
]
export default routes
