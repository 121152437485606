import apiClient from 'services/axios'
const API = {
  get: '/setting',
  set: '/setting'
}
export const get = async () => {
  const response = await apiClient.get(API.get)
  if (response) {
    return response.data
  }
}
export const set = async (data) => {
  const response = await apiClient.post(API.set, data)
  if (response) {
    return response.data
  }
}
export const getPublicSetting = async () => {
  const response = await apiClient.get('/api/settings/title')
  if (response) {
    return response.data
  }
}
