import { isEqual, xorWith } from 'lodash'
import moment from 'moment'
import { TIME_FORMAT } from 'constant'

export const replaceAll = (str = '', find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace)
}

const isItDamnNumber = (data) => {
  return typeof data === 'number' && !isNaN(data)
}

export const valueDisplay = (value, maxFractionDigits = 2) => {
  if (!isItDamnNumber(value) || !isItDamnNumber(maxFractionDigits)) {
    return '-'
  }
  const result = parseFloat(value.toFixed(maxFractionDigits)).toString() || 0
  return result
}

export const valueDisplayTime = (value) => {
  if (!value) {
    return '-'
  }
  return moment(value).format(TIME_FORMAT)
}
/**
 * Convert an array to a tree
 * @param {array} allNodes all items in a tree
 * @param {array} nodes a branch
 * @returns
 */
export const findNodeRecurse = ({ allNodes = [], nodes = [] }) => {
  if (!nodes.length) {
    return
  }

  for (const node of nodes) {
    const children = allNodes.filter(x => x.cabinetGroupId === node.id)
    node.children = children

    // node.children = children.map(x => ({ ...x, disabled: node.disabled }))
    findNodeRecurse({ allNodes, nodes: children })
  }
}
export const getCabinetGroupPath = (ancestorCabinetGroups = []) => {
  if (!ancestorCabinetGroups.length) {
    return ''
  }
  let result = ''
  for (const item of ancestorCabinetGroups) {
    result += `${item.name}/`
  }
  return result.substring(0, result.length - 1)
}
export const getNodeRecurseForDropdown = ({ allNodes = [], nodes = [] }) => {
  if (!nodes.length) {
    return
  }
  for (const node of nodes) {
    const children = allNodes.filter(x => x.cabinetGroupId === node.id)
    if (!children.length) {
      continue
    }
    for (const child of children) {
      child.label = `--${child.label}`
    }
    // node.children = children.map(x => ({ ...x, disabled: node.disabled }))
    getNodeRecurseForDropdown({ allNodes, nodes: children })
  }
}
export const getRoot = ({ allNodes, key }) => {
  let breadcrumb
  while (key) {
    if (!key) {
      return breadcrumb
    }
    // eslint-disable-next-line no-loop-func
    const parent = allNodes.find(x => x.key === key)
    if (!parent) {
      return breadcrumb
    }
    key = parent.cabinetGroupId
    if (!breadcrumb) {
      breadcrumb = parent.name
      continue
    }
    breadcrumb = `${parent.name}/ ${breadcrumb}`
  }

  return breadcrumb
  // if (!key) {
  //   return breadcrumb
  // }
  // const parent = allNodes.find(x => x.key === key)
  // if (!parent) {
  //   return breadcrumb
  // }
  // breadcrumb = `${parent.name}/${breadcrumb}`
  // getRoot({ allNodes, key: parent.cabinetGroupId, breadcrumb })
}

export const getParentKey = (key, tree) => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

const testFindNodeRecurse = () => {
  const args = [{
    id: 1,
    name: 'root1',
    cabinetGroupId: null
  }, {
    id: 2,
    name: 'root2',
    cabinetGroupId: null
  }, {
    id: 3,
    name: 'child1OfRoot1',
    cabinetGroupId: 1
  }, {
    id: 4,
    name: 'child2OfRoot1',
    cabinetGroupId: 1
  }, {
    id: 5,
    name: 'child1OfRoot2',
    cabinetGroupId: 2
  }, {
    id: 6,
    name: 'child1OfRoot1Child2',
    cabinetGroupId: 4
  }]
  const expected = [{
    id: 1,
    name: 'root1',
    cabinetGroupId: null,
    children: [{
      id: 3,
      name: 'child1OfRoot1',
      cabinetGroupId: 1,
      children: []
    }, {
      id: 4,
      name: 'child2OfRoot1',
      cabinetGroupId: 1,
      children: [{
        id: 6,
        name: 'child1OfRoot1Child2',
        cabinetGroupId: 4,
        children: []
      }]
    }]
  }, {
    id: 2,
    name: 'root2',
    cabinetGroupId: null,
    children: [{
      id: 5,
      name: 'child1OfRoot2',
      cabinetGroupId: 2,
      children: []
    }]
  }]
  const roots = args.filter(x => !x.cabinetGroupId)
  findNodeRecurse({ allNodes: args, nodes: roots })
  const success = xorWith(expected, roots, isEqual).length
  console.info('testFindNodeRecurse success: ', !success)
}

testFindNodeRecurse()
