import apiClient from 'services/axios'
import { apis } from 'services/api'
import { detailApi, createApi, updateApi, destroyApi } from '../index'
const API = {
  count: '/api/alerts/count',
  list: '/api/alerts',
  detail: '/api/alerts/{0}', // api/alert/:id
  create: '/api/alerts',
  update: '/api/alerts/{0}', // api/alert/:id
  delete: '/api/alerts/{0}' // api/warning/:id,
}
export const count = async ({ where = '', start = '', end = '' }) => {
  const response = await apiClient.get(`${API.count}?where=${where}&start=${start}&end=${end}`)
  if (response) {
    return response.data
  }
}
export const list = async ({ filter, start = '', end = '' }) => {
  const response = await apiClient.get(`${API.list}?filter=${filter}&start=${start}&end=${end}`)
  if (response) {
    return response.data
  }
}
export const detail = async (id) => {
  return detailApi(API, id)
}
export const create = async (data) => {
  return createApi(API, data)
}
export const updateNote = async ({ id, alertId, body }) => {
  const api = apis.CABINETS_PATCH_ID_ALERTS_ALERTID({ id, alertId, body })
  const response = await apiClient({
    method: api.method,
    url: api.url,
    data: body
  })
  if (response) {
    return response.data
  }
}
export const update = async (id, data) => {
  return updateApi(API, id, data)
}
export const destroy = async (id) => {
  return destroyApi(API, id)
}
