import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import { TYPES, callApi } from 'services'

export const COMMON = ({ type, field, actionType }) => {
  return function * ({ payload }) {
    yield loading(true)
    let response = yield call(callApi, { type, payload })
    if (response) {
      if (type.toLowerCase().indexOf('count') > -1) {
        response = response.count
      }
      yield put({
        type: actionType || 'notification/SET_STATE',
        payload: {
          [field]: response
        }
      })
    }
    yield loading(false)
  }
}
function * loading (isLoading = false) {
  yield put({
    type: 'notification/SET_STATE',
    payload: {
      loading: isLoading
    }
  })
}
export default function * rootSaga () {
  yield all([
    takeEvery(actions.COUNT, COMMON({ type: TYPES.NOTIFICATIONS_GET_COUNT, field: 'total' })),
    takeEvery(actions.LIST, COMMON({ type: TYPES.NOTIFICATIONS_GET, field: 'list' })),
    takeEvery(actions.READ, COMMON({ type: TYPES.NOTIFICATIONS_POST_READS, field: 'detail' })),
    takeEvery(actions.COUNT_UNREAD, COMMON({ type: TYPES.NOTIFICATIONS_GET_COUNT, field: 'totalUnread' })),
    takeEvery(actions.LIST_UNREAD, COMMON({ type: TYPES.NOTIFICATIONS_GET, field: 'listUnread' }))
  ])
}
