import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import style from './style.module.scss'

const { Content } = Layout
const mapStateToProps = ({ settings }) => ({
  test: 1
})

const AuthLayout = ({ children }) => {
  return (
    <Layout>
      <Content>
        <div className={style.containerInner}>{children}</div>
      </Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
