import React, { useEffect } from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
import store from 'store'
import './style.scss'

const mapStateToProps = ({ settings, setting, notification, dispatch }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  detail: setting.detail,
  notification,
  dispatch
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  detail,
  notification,
  dispatch
}) => {
  const { value = {} } = detail
  const { mainTitle, subTitle } = value
  useEffect(() => {
    dispatch({
      type: 'notification/COUNT_UNREAD',
      payload: { where: JSON.stringify({ read: false }) }
    })
    dispatch({
      type: 'notification/LIST_UNREAD',
      payload: { filter: JSON.stringify({ where: { read: false }, limit: 5, order: ['created DESC'] }) }
    })
  }, [dispatch])
  // polling
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({
        type: 'notification/COUNT_UNREAD',
        payload: { where: JSON.stringify({ read: false }) }
      })
      dispatch({
        type: 'notification/LIST_UNREAD',
        payload: { filter: JSON.stringify({ where: { read: false }, limit: 5, order: ['created DESC'] }) }
      })
    }, 1000 * 30)
    return () => clearInterval(interval)
  }, [dispatch])
  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless
        })}
      >
        {/* <Sidebar /> */}
        {/* <SupportChat /> */}
        <Menu />
        <Layout>
          <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar
            })}
          >
            <TopBar
              mainTitle={mainTitle}
              subTitle={subTitle}
              notification={notification}
              dispatch={dispatch}
            />
          </Layout.Header>
          <Breadcrumbs />
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className='cui__utils__content warning-line'>
              {store.get('offline') === true &&
                <div className='flash-container flash-container-page sticky'>
                  <div className='flash-alert'>
                    <div className='flash-text'>
                    Không thể tải dữ liệu từ máy chủ, vui lòng kiểm tra đường truyền internet.
                      <div className='close-icon-wrapper js-close-icon' />
                    </div>
                  </div>
                </div>}
              {children}
            </div>
          </Layout.Content>
          {/* <Layout.Footer>
            <Footer />
          </Layout.Footer> */}
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
