import actions from './actions'

const initialState = {
  list: [],
  detail: {},
  total: 0
}
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_NOTE_STATE: {
      const alert = (state.list || []).find(x => x.id === action.payload.id)
      if (alert) {
        alert.note = action.payload.note
      }
      return state
    }
    default:
      return state
  }
}
