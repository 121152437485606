// define some action
const ENTITY = 'notification'
const actions = {
  // SET
  SET_STATE: `${ENTITY}/SET_STATE`,
  SET_LIST_STATE: `${ENTITY}/SET_LIST_STATE`,
  // GET
  COUNT: `${ENTITY}/COUNT`,
  COUNT_UNREAD: `${ENTITY}/COUNT_UNREAD`,
  LIST: `${ENTITY}/LIST`,
  LIST_UNREAD: `${ENTITY}/LIST_UNREAD`,
  READ: `${ENTITY}/READ`
}

export default actions
