import * as configDefault from './config.default'

// eslint-disable-next-line
const NODE_ENV = 'production';
// eslint-disable-next-line
const API_URL = 'https://dtms-backend.tuanan.com';
// eslint-disable-next-line
const GOOGLE_KEY = 'AIzaSyAS1dhF40k4KiXFbW5wPVZGFeT6cnCko7I';
// eslint-disable-next-line
const ALERT_RANGE = '';

const config = {
  NODE_ENV: NODE_ENV.startsWith('$') ? configDefault.NODE_ENV : NODE_ENV,
  API_URL: API_URL.startsWith('$') ? configDefault.API_URL : API_URL,
  GOOGLE_KEY: GOOGLE_KEY.startsWith('$') ? configDefault.GOOGLE_KEY : GOOGLE_KEY,
  ALERT_RANGE: ALERT_RANGE.startsWith('$') ? configDefault.ALERT_RANGE : ALERT_RANGE
}
export default config
