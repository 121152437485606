// define some action
const ENTITY = 'setting'
const actions = {
  // SET
  SET_STATE: `${ENTITY}/SET_STATE`,
  // GET
  DETAIL: `${ENTITY}/DETAIL`,
  UPDATE: `${ENTITY}/UPDATE`,
  DATA_TYPE: `${ENTITY}/DATA_TYPE`,
  ALERT_TYPE: `${ENTITY}/ALERT_TYPE`
}

export default actions
