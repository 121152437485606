const actions = {
  SET_STATE: 'alert/SET_STATE',
  SET_NOTE_STATE: 'alert/SET_NOTE_STATE',
  COUNT: 'alert/COUNT',
  LIST: 'alert/LIST',
  DETAIL: 'alert/DETAIL',
  CREATE: 'alert/CREATE',
  UPDATE: 'alert/UPDATE',
  DELETE: 'alert/DELETE',
  UPDATE_NOTE: 'alert/UPDATE_NOTE'
}
export default actions
