import actions from './actions'
import { uniqBy } from 'lodash'

const initialState = {
  list: [],
  movings: [],
  shortList: [],
  detail: {},
  total: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SHORT_LIST:
      return { ...state, shortList: uniqBy([...state.shortList, ...action.payload], x => x.id) }
    case actions.SET_SHORT_LIST_SEARCH:
      return { ...state, shortList: action.payload }
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_LIST_STATE:
      return { ...state, list: state.list.filter(x => x.id !== action.payload.id) }
    default:
      return state
  }
}
