import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import { preConfirm } from 'services/jwt'
import { TYPES, callApi } from 'services'
import { history } from 'index'
import { notification } from 'antd'

export const COMMON = ({ type, field, actionType }) => {
  return function * ({ payload }) {
    yield loading(true)
    if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1 || type.toLowerCase().indexOf('delete') > -1) {
      const data = payload.data || payload.body
      const { password } = data
      yield loading(true)
      // preconfirm
      const success = yield call(preConfirm, password)
      if (!success) {
        yield put({
          type: 'account/SET_STATE',
          payload: {
            preConfirm: true
          }
        })
        notification.warning({
          message: 'Lỗi!',
          description: 'Mật khẩu không đúng!'
        })
        yield loading(false)
        return
      }
      delete data.password
    }
    let response = yield call(callApi, { type, payload })
    if (response) {
      if (type.toLowerCase().indexOf('count') > -1) {
        response = response.count
      }
      if (type.toLowerCase().indexOf('delete') > -1) {
        actionType = 'zone/SET_LIST_STATE'
        response = payload.id
      }
      yield put({
        type: actionType || 'zone/SET_STATE',
        payload: {
          [field]: response
        }
      })
      if (type.toLowerCase().indexOf('post') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `Khu vực ${response.name} đã được tạo thành công!`
        })
        history.push(`/zones/${response.id}`)
      }
      if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `Khu vực ${response.name} đã được cập nhật thành công!`
        })
        history.push(`/zones/${response.id}`)
      }
      if (type.toLowerCase().indexOf('delete') > -1) {
        notification.success({
          message: 'Thành công!',
          description: 'Khu vực đã được xóa thành công!'
        })
      }
    }
    yield loading(false)
  }
}
function * loading (isLoading = false) {
  yield put({
    type: 'zone/SET_STATE',
    payload: {
      loading: isLoading
    }
  })
}
export default function * rootSaga () {
  yield all([
    takeEvery(actions.COUNT, COMMON({ type: TYPES.ZONES_GET_COUNT, field: 'total' })),
    takeEvery(actions.LIST, COMMON({ type: TYPES.ZONES_GET, field: 'list' })),
    takeEvery(actions.CREATE, COMMON({ type: TYPES.ZONES_POST, field: 'detail' })),
    takeEvery(actions.DETAIL, COMMON({ type: TYPES.ZONES_GET_ID, field: 'detail' })),
    takeEvery(actions.UPDATE, COMMON({ type: TYPES.ZONES_PATCH_ID, field: 'detail' })),
    takeEvery(actions.DELETE, COMMON({ type: TYPES.ZONES_DELETE_ID, field: 'id' })),
    takeEvery(actions.STATS, COMMON({ type: TYPES.ZONES_GET_ID_STATS, field: 'stats' }))
  ])
}
