
import apiClient from 'services/axios'
import { types } from 'services/api'
import moment from 'moment'
import { callApi } from 'services'
import { listApi, detailApi, createApi, updateApi, destroyApi } from '../index'
const API = {
  count: '/api/cabinets/count',
  list: '/api/cabinets',
  detail: '/api/cabinets/{0}',
  create: '/api/cabinets',
  update: '/api/cabinets/{0}',
  delete: '/api/cabinets/{0}',
  configure: '/api/cabinets/{0}/configure'
}
export const list = async ({ filter, limit = 10, skip = 0, name, zone, dtms }) => {
  return listApi(API, { filter, limit, skip, name, zone, dtms })
}
export const detail = async (id) => {
  return detailApi(API, id)
}
export const create = async (data) => {
  return createApi(API, data)
}
export const update = async (id, data) => {
  return updateApi(API, id, data)
}
export const destroy = async (id) => {
  return destroyApi(API, id)
}
export const setConfigure1 = async (id, body) => {
  const response = await apiClient.patch(API.configure.replace('{0}', id), body)
  if (response) {
    return response.data
  }
}
export const setConfigure = async (id, body) => {
  const response = await apiClient.patch(API.configure.replace('{0}', id), body)
  if (!response || !response.data) {
    return
  }
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      console.info('pooling for waiting device configurate...')
      const response = await apiClient.get(`${API.detail.replace('{0}', id)}?filter=${JSON.stringify({
        include: [{
          relation: 'device',
          scope: {
            include: 'outgoingFeederChannels'
          }
        }, {
          relation: 'user'
        }, {
          relation: 'zone'
        }]
      })}`)
      if (!response || !response.data || !response.data.device) {
        console.info('clear interval due to error')
        clearInterval(interval)
        return resolve()
      }
      const { setConfigReqVersion, setConfigResVersion } = response.data.device
      if (setConfigResVersion >= setConfigReqVersion) {
        clearInterval(interval)
        console.info('config was updated! let notice success!')
        return resolve(response.data.device)
      }
    }, 1000)

    setTimeout(() => {
      console.info('timed out, clearing interval')
      clearInterval(interval)
      return resolve()
    }, 30000)
  })
}
export const control = async ({ id, body }) => {
  const response = await callApi({ type: types.CABINETS_POST_ID_CONTROL, payload: { id, body } })
  if (!response) {
    return
  }
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      console.info('polling for waiting device control report status...')
      const response = await callApi({ type: types.CABINETS_GET_ID_CONTROL_STATUS, payload: { id, filter: JSON.stringify({ include: [{ relation: 'device' }] }) } })
      if (!response) {
        console.info('clear interval due to error')
        clearInterval(interval)
        return resolve()
      }
      let { control = {}, realtimeStatuses = [] } = response
      if (!control) {
        control = {}
      }
      const sgs = realtimeStatuses.find(x => x.type === 'SGS') || {}
      const {
        controlReqAt,
        controlResAt,
        err
      } = control
      const {
        deviceId: deviceIdStatus,
        timestamp,
        value
      } = sgs

      if (err) {
        clearInterval(interval)
        console.info('control throw err!')
        return resolve()
      }
      if (deviceIdStatus &&
        controlResAt &&
        value === control.DO1 &&
        moment(controlResAt).isAfter(controlReqAt) &&
        moment(timestamp).add(10, 'seconds').isAfter(controlResAt)
      ) {
        clearInterval(interval)
        console.info('control complete! let notice success!')
        return resolve(response)
      }
    }, 1000)

    setTimeout(() => {
      console.info('timed out, clearing interval')
      clearInterval(interval)
      return resolve()
    }, 2 * 60 * 1000)
  })
}
