import actions from './actions'

// just change this initialState => should equal simple data response from api
const initialState = {
  data: [
    {
      name: 'kibo',
      address: 'Nhà trọ'
    },
    {
      name: 'ki bó mô tố',
      address: 'Mọi nơi'
    },
    {
      name: 'mô tố rô lá',
      address: 'Ở quê'
    }
  ]
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
