import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import actions from './actions'

export function * GET_DATA () {
  const state = yield select()
  const { role, zoneId } = state.user
  const menuData = yield call(getMenuData, { role, zoneId })
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData
    }
  })
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    GET_DATA() // run once on app load to fetch menu data
  ])
}
