import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import cabinet from './cabinet/sagas'
import zone from './zone/sagas'
import account from './account/sagas'
import warning from './warning/sagas'
import monitoring from './monitoring/sagas'
import setting from './setting/sagas'
import notification from './notification/sagas'
import cabinetGroup from './cabinet-group/sagas'

export default function * rootSaga () {
  yield all([user(), menu(), settings(), cabinet(), zone(), account(), warning(), monitoring(), setting(), notification(), cabinetGroup()])
}
