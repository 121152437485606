import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import { preConfirm } from 'services/jwt'
import { TYPES, callApi } from 'services'
import { setConfigure, control } from 'services/cabinet'
import { history } from 'index'
import { notification } from 'antd'
// import moment from 'moment'

export const COMMON = ({ type, field, actionType }) => {
  return function * ({ payload }) {
    yield loading(true)
    if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1 || type.toLowerCase().indexOf('delete') > -1) {
      const data = payload.data || payload.body
      const { password } = data
      yield loading(true)
      // preconfirm
      const success = yield call(preConfirm, password)
      if (!success) {
        yield put({
          type: 'account/SET_STATE',
          payload: {
            preConfirm: true
          }
        })
        notification.warning({
          message: 'Lỗi!',
          description: 'Mật khẩu không đúng!'
        })
        yield loading(false)
        return
      }
      delete data.password
    }
    let response = yield call(callApi, { type, payload })
    if (response) {
      if (type.toLowerCase().indexOf('count') > -1) {
        response = response.count
      }
      if (type.toLowerCase().indexOf('delete') > -1) {
        actionType = 'cabinet/SET_LIST_STATE'
        response = payload.id
      }
      yield put({
        type: actionType || 'cabinet/SET_STATE',
        payload: {
          [field]: response
        }
      })
      if (type.toLowerCase().indexOf('post') > -1 &&
      type.toLowerCase().indexOf('accept') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `${payload.body.accept ? 'Chấp nhận' : 'Từ chối'} lắp trạm biến áp ${response.name} thành công!`
        })
        yield loading(false)
        return
      }
      if (type.toLowerCase().indexOf('post') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `Trạm biến áp ${response.name} đã được tạo thành công!`
        })
        history.push(`/cabinets/${response.id}`)
      }
      if (type.toLowerCase().indexOf('patch') > -1 || type.toLowerCase().indexOf('put') > -1) {
        notification.success({
          message: 'Thành công!',
          description: `Trạm biến áp ${response.name} đã được cập nhật thành công!`
        })
        history.push(`/cabinets/${response.id}`)
      }
      if (type.toLowerCase().indexOf('delete') > -1) {
        notification.success({
          message: 'Thành công!',
          description: 'Trạm biến áp đã được xóa thành công!'
        })
      }
    }
    yield loading(false)
  }
}
export function * SET_CONFIGURE ({ payload }) {
  const { body, id } = payload
  yield loading(true)

  const response = yield call(setConfigure, id, body)
  if (response) {
    yield put({
      type: 'cabinet/SET_CONFIGURE_STATE',
      payload: response
    })
    notification.success({
      message: 'Thành công!',
      description: 'Cấu hình thiết bị thành công!'
    })
  } else {
    notification.error({
      message: 'Thất bại!',
      description: 'Cấu hình thiết bị thất bại!'
    })
  }
  yield loading(false)
}
export function * SHORT_LIST ({ payload }) {
  yield loading(true)

  const response = yield call(callApi, { type: TYPES.CABINETS_GET, payload })
  if (response) {
    yield put({
      type: 'cabinet/SET_SHORT_LIST',
      payload: response
    })
  }
  yield loading(false)
}
export function * SHORT_LIST_SEARCH ({ payload }) {
  yield loading(true)

  const response = yield call(callApi, { type: TYPES.CABINETS_GET, payload })
  if (response) {
    yield put({
      type: 'cabinet/SET_SHORT_LIST_SEARCH',
      payload: response
    })
  }
  yield loading(false)
}
export function * CONTROL ({ payload }) {
  const { id, payload: body } = payload
  yield loading(true)

  const response = yield call(control, { id, body })
  if (response) {
    yield put({
      type: 'cabinet/SET_CONTROL_STATUS_POLLING',
      payload: { controlState: response }
    })
    notification.success({
      message: 'Thành công!',
      description: 'Điều khiển thiết bị thành công!'
    })
  } else {
    notification.error({
      message: 'Thất bại!',
      description: 'Điều khiển thiết bị thất bại!'
    })
  }
  yield loading(false)
}
export function * CONTROL_STATE_POLLING ({ payload }) {
  const response = yield call(callApi, { type: TYPES.CABINETS_GET_ID_CONTROL_STATUS, payload })
  if (response) {
    yield put({
      type: 'cabinet/SET_CONTROL_STATUS_POLLING',
      payload: { controlState: response }
    })
  }
}
function * loading (isLoading = false, timeout = 0) {
  yield put({
    type: 'cabinet/SET_STATE',
    payload: {
      loading: isLoading
    }
  })
}
export default function * rootSaga () {
  yield all([
    takeEvery(actions.COUNT, COMMON({ type: TYPES.CABINETS_GET_COUNT, field: 'total' })),
    takeEvery(actions.LIST, COMMON({ type: TYPES.CABINETS_GET, field: 'list' })),
    takeEvery(actions.LISTV2, COMMON({ type: TYPES.CABINETS_GET_V2_LIST, field: 'listv2' })),
    takeEvery(actions.CREATE, COMMON({ type: TYPES.ZONES_POST_ID_CABINETS, field: 'detail' })),
    takeEvery(actions.DETAIL, COMMON({ type: TYPES.CABINETS_GET_ID, field: 'detail' })),
    takeEvery(actions.UPDATE, COMMON({ type: TYPES.CABINETS_PATCH_ID, field: 'detail' })),
    takeEvery(actions.ACCEPT, COMMON({ type: TYPES.CABINETS_POST_ID_ACCEPT_DEVICE, field: 'detail' })),
    takeEvery(actions.DELETE, COMMON({ type: TYPES.CABINETS_DELETE_ID, field: 'id' })),
    takeEvery(actions.ANALYZE_ALERT, COMMON({ type: TYPES.CABINETS_GET_ID_ANALYZE_ALERT, field: 'analyzeAlerts' })),
    takeEvery(actions.CONTROL_STATE, COMMON({ type: TYPES.CABINETS_GET_ID_CONTROL_STATUS, field: 'controlState' })),
    takeEvery(actions.CONTROL_STATE_POLLING, CONTROL_STATE_POLLING),
    takeEvery(actions.CONTROL, CONTROL),
    takeEvery(actions.SET_CONFIGURE, SET_CONFIGURE),
    takeEvery(actions.SHORT_LIST, SHORT_LIST),
    takeEvery(actions.SHORT_LIST_SEARCH, SHORT_LIST_SEARCH),
    takeEvery(actions.RAW_DATA, COMMON({ type: TYPES.CABINETS_GET_ID_RAW_DATA, field: 'rawData' })),
    takeEvery(actions.RAW_DATA_CHART_30M, COMMON({ type: TYPES.CABINETS_GET_ID_RAW_DATA, field: 'rawData30m' })),
    takeEvery(actions.RAW_DATA_GRID_30M, COMMON({ type: TYPES.CABINETS_GET_ID_RAW_DATA, field: 'rawData30mGrid' })),
    takeEvery(actions.CHART_DATA, COMMON({ type: TYPES.CABINETS_GET_ID_CHART_DATA, field: 'chartData' })),
    takeEvery(actions.EXPORT_GENERAL_DATA, COMMON({ type: TYPES.CABINETS_GET_ID_GENERAL_EXPORT_DATA, field: 'generalExportData' })),
    takeEvery(actions.EXPORT_OUTGOINGFEEDER_DATA, COMMON({ type: TYPES.CABINETS_GET_ID_OUTGOINGFEEDER_EXPORT_DATA, field: 'outgoingFeederExportData' })),
    takeEvery(actions.EXPORT_EMETER_DATA, COMMON({ type: TYPES.CABINETS_GET_ID_EMETER_EXPORT_DATA, field: 'emeterExportData' })),
    takeEvery(actions.EXPORT_EMETER_COUNTER_DATA, COMMON({ type: TYPES.CABINETS_GET_ID_EMETERCOUNTER_EXPORT_DATA, field: 'emeterExportCountData' })),
    takeEvery(actions.EXPORT_EMETER_30M_DATA, COMMON({ type: TYPES.CABINETS_GET_ID_EXPORT_EMETERLOAD_30MINVIEW_DATA, field: 'emeterExport30mData' }))
  ])
}
